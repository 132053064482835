import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    name: "not-found",
    metaInfo: {
        title: "Page not found",
    },
})
export default class NotFound extends Vue { }
